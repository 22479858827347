<template>
<div>
  <div class="flex ju-between">
    <div style="color:red;font-size:16px">该账单车辆总数 {{dataTotal}}，当前可开票车辆 {{dataList.length}}，如有不对请检查！</div>
    <a-popconfirm
    title="确认保存开票?"
    ok-text="确定"
    cancel-text="取消"
    @confirm="submit"
    :disabled="state.selectedRowKeys.length==0"
  >
  <a-button class="m-r3" style="height: 40px;;width: 150px;" :disabled="state.selectedRowKeys.length==0" type="primary">保存开票</a-button>
  </a-popconfirm>
  
  </div>
  <div class="contentBox">
        <div class="tips">
          <span>已选择<span style="margin: 0 5px;color:red">{{ state.selectedRowKeys.length }}</span> 辆</span>
          <span class="m-l2">合计开票金额：<span style="color:red">￥{{ state.calculatedAmount.toFixed(2) }}</span></span>
          <a class="m-l4" @click="state.selectedRowKeys=[],state.calculatedAmount=0">清空选择</a>
        </div>
        <div>
          <a-table  ref="myTable"   :scroll="{ x:0,y:450  }" :rowKey="(record, index) => { return index;}"    size="small"   bordered :pagination="false" :row-selection="{ selectedRowKeys:state.selectedRowKeys, onChange: onSelectChange }" :columns="columns" :data-source="dataList">
            <template #vinNo="{record }">
             {{  record.vinNo }}  {{ record.brand }} {{ record.model }}
            </template>
            <template #settlementStatus="{ record  }">
                {{ record.settlementStatus.label }}
            </template>
            <template #taxRate="{ record }">
              <div v-if="record.taxRate">
                 {{ record.taxRate }} %
              </div>
            </template>
            <template #invoiceAmt="{ record }">
              <div v-if="record.invoiceAmt">
                {{ record.invoiceAmt  }}
              </div>
            </template>
          </a-table>
        </div>
      </div>
</div>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { ref, reactive, onMounted } from 'vue'
import { vehicleInvoice,billInvoiceAdd } from '@/api/finance/finance.js';
const props = defineProps({
  invoicingList: {
    type: Array,
    default: () => []
  },
  reconciliationDetail:{
    type: Object,
    default: () => {}
  },
  dataTotal:{
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['submitOk'])
const isSuccess = ref(false)
const dataList = ref(props.invoicingList)
const state = reactive({

  loading: false,
  selectedRowKeys: [],
  timer: null,
  content: '',
  calculatedAmount:0,
  invoiceCarrier:{
    value: null,
    width: 220,
    placeholder: '开票主体',
    opt:[]
  },
  taxItem:{
    value: null,
    width: 220,
    placeholder: '开票税率',
    opt:[]
  },
})

//保存发票
const submit = () => {
  if(isSuccess.value) return
  if(state.selectedRowKeys.length == 0){
    message.warn('请选择车辆')
    return
  }

 let list = []
 state.selectedRowKeys.map(key =>{
   list.push(dataList.value[key])
 })
 let applyDetails = []
 if(!list.every(item => item.invoiceCarrier ==  list[0].invoiceCarrier)){
    message.warn('开票主体不一致，请从新选择')
    return
  }
  if(!list.every(item => item.taxRate ==  list[0].taxRate)){
    message.warn('开票税率不一致，请从新选择')
    return
  }
  isSuccess.value = true
list.map( item => {
    applyDetails.push({
    applyAmt:item.invoiceAmt,
    orderVehicleInvoiceId:item.orderVehicleInvoiceId
    })
 })
 let obj = {
  amt: (state.calculatedAmount).toFixed(2),
  applyDetails:applyDetails,
  customerId:props.reconciliationDetail.customerId,
  customerName:props.reconciliationDetail.customerName
 }
 billInvoiceAdd(obj).then( res => {
  if(res.code !== 10000) return
   emit('submitOk',res.data)
 }).finally(() => {
  isSuccess.value = false
 })

}
const onSelectChange = (selectedRowKeys,e) => {
  state.calculatedAmount = calculatedAmount(e,'invoiceAmt')
  state.selectedRowKeys = selectedRowKeys;
};

const calculatedAmount = (arr,type) => {
  if(!arr) return ''
  let num = 0
  arr.forEach( item => {
    num += Number(item[type])
  })
  return num
}

const columns = [
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    key:'vinNo',
    align: 'center',
    slots:{ customRender:'vinNo' }
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    key:'customerName',
    align: 'center',
    width: "100px"
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    key:'orderId',
    align: 'center',
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    key:'orderStatus',
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    key: 'pushTime',
    align: 'center'
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    key:'orderId',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    key:'salesman',
    align: 'center',
    width:80
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    key:'orgName',
    align: 'center',
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus',
    key:'settlementStatus',
    align: 'center',
    slots:{ customRender:'settlementStatus' }
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key:'invoiceCarrier',
    align: 'center',
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    key:'taxRate',
    align: 'center',
    slots:{ customRender:'taxRate' },
    width:80
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    key:'taxItem',
    align: 'center',
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmt',
    key:'invoiceAmt',
    align: 'center',
    slots:{ customRender:'invoiceAmt' }
  },
];
</script>

<style lang="less" scoped>
.contentBox {
  .tips {
    height: 40px;
  }
}
:deep(.ant-popover-inner-content){
  padding: 12px 16px;
}
</style>