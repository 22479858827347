<template>
  <a-card :style="{ marginTop: '24px' }" :bodyStyle="{padding:0}">
    <a-tag color="pink" class="select-table-info">
      <template #icon>
        <InfoCircleFilled />
      </template>
      已选择 <strong>{{selectedOrderRowKeys.length}}</strong> 项
      订单金额总计 <strong>{{orderAmt}}</strong> 元
      <a @click="selectedOrderRowKeys = []">清空选择</a>
    </a-tag>
    <a-button @click="editReconciliation" type="primary">添加</a-button>
    <template #extra>
      <a-space>
        <a-input v-model:value="orderreconciliationAdd.orderId" placeholder="合同号"></a-input>
        <a-input v-model:value="orderreconciliationAdd.vinNo" placeholder="车架号"></a-input>
        <a-input v-model:value="orderreconciliationAdd.settlementMode" placeholder="挂账类型"></a-input>
        <a-input v-model:value="orderreconciliationAdd.salesman" placeholder="业务员"></a-input>
        <a-input v-model:value="orderreconciliationAdd.customerName" placeholder="客户名"></a-input>
        <a-range-picker v-model:value="orderreconciliationAdd.date" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="orderSearchData" />
        <a-button type="primary" @click="orderSearchData">查询</a-button>
      </a-space>
    </template>
    <a-table :row-selection="{ selectedRowKeys: selectedOrderRowKeys, onChange: selectOrderChange }" :dataSource="orderList" :rowKey="record => record.id" size="small" bordered>
      <a-table-column :width="100" title="合同编号" data-index="orderId" />
      <a-table-column :width="80" title="结算类型" data-index="settlementMode.label" />
      <a-table-column :width="80" title="挂账类型" data-index="creditType.label" />
      <a-table-column :width="150" title="客户名" data-index="customerName" />
      <a-table-column :width="80" title="业务员" data-index="salesman" />
      <a-table-column title="起运地" data-index="startAddress" />
      <a-table-column title="目的地" data-index="endAddress" />
      <a-table-column :width="150" title="下单时间" data-index="orderTime" />
      <a-table-column :width="150" title="起运时间" data-index="startTime" />
      <a-table-column :width="80" title="结算金额" data-index="settableAmt">
        <template #default="{record}">
          <div>
            <span v-show="record.editStatus"> {{record.settableAmt}}</span>
            <a-input v-show="!record.editStatus" v-model:value="record.settableAmt" :placeholder="record.settableAmt" style="width:85px;" /><br />
            <a @click="editBtn(record)" v-if="selectedOrderRowKeys.indexOf(record.id) > -1">{{record.editStatus ? '编辑' : '确认'}}</a>
          </div>
        </template>
      </a-table-column>
      <a-table-column :width="120" title="小车信息" data-index="vehicleList">
        <template #default="{ record }">
          <div v-for="(item,index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column :width="100" title="到收金额" data-index="paymentArrival" />
      <a-table-column :width="80" title="结算方式" data-index="orderSettlement.label" />
      <a-table-column :width="80" title="结算状态" data-index="settlementStatus.label" />
    </a-table>
  </a-card>
</template>
<script>
import { billOrder, reconciliationEdit } from '@/api/trans/finance/bill'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
export default {
  props: { customerId: { type: String, default: '' }, type: { type: String, default: '' }, reconciliationId: { type: String, default: '' } },
  setup (props, { emit }) {
    const orderListRef = ref()
    const transportListRef = ref()
    const paymentRef = ref()
    const state = reactive({
      selectedOrderRowKeys: [],
      orderreconciliationAdd: { date: [] },
      transportreconciliationAdd: { date: [] },
      reconciliation: {
        id: ''
      },
      total: {},
      orderList: [],
      orderPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.orderPagination.current = page
          state.orderPagination.pageSize = pageSize
          loadOrderPage()
        }
      },
      timeOut: null,
      orderAmt: 0
    })

    const editBtn = (record) => {
      if (Number(record.settableAmt) > 0) {
        if (Number(record.settableAmt) > Number(record.applyAmt)) {
          message.error('数额高于最' + Number(record.applyAmt) + '大值')
          return
        }
        if (!record.editStatus) {
          state.reconciliation.orderSettlementIds.forEach(item => {
            if (item.id === record.id) {
              state.orderAmt = state.orderAmt - Number(item.applyAmt) + Number(record.settableAmt)
              item.applyAmt = Number(record.settableAmt)
            }
          })
        }
        record.editStatus = !record.editStatus
      } else { message.error('数额不能小于0') }
    }
    const loadOrderPage = () => {
      state.reconciliation.id = props.reconciliationId
      billOrder(props.customerId, {
        ...state.orderreconciliationAdd,
        billStatus: 0,
        type: props.type,
        startTime: state.orderreconciliationAdd.date[0],
        endTime: state.orderreconciliationAdd.date[1]
      }).then(res => {
        if (res.code === 10000) {
          state.orderList = res.data
          if (state.orderList.length > 0) {
            state.orderList.forEach(o => {
              o.editStatus = true
              o.applyAmt = o.settableAmt
            })
          }
        } else {
          state.orderList = []
        }
      })
    }

    const orderSearchData = () => {
      state.orderCheckedList = []
      state.selectedOrderRowKeys = []
      state.orderPagination = {}
      loadOrderPage()
    }

    const selectOrderChange = (selectedRowKeys, selectedRows) => {
      state.selectedOrderRowKeys = selectedRowKeys
      state.reconciliation.orderSettlementIds = selectedRowKeys.map(item => { return { id: item } })

      state.orderAmt = 0
      state.reconciliation.orderSettlementIds = []
      state.selectedOrderRowKeys = selectedRowKeys
      selectedRows.forEach(item => {
        if (Number(item.settableAmt) > Number(item.applyAmt)) {
          item.settableAmt = item.applyAmt
        }
        state.orderAmt += Number(item.settableAmt)
      })
      state.reconciliation.orderSettlementIds = selectedRows.map(item => { return { id: item.id, applyAmt: item.settableAmt } })
    }

    // const clearAccounts = () => {
    //   state.orderAmt = 0
    //   state.orderList.forEach(res => {
    //     res.editStatus = true
    //     res.applyAmt = res.settableAmt
    //     if (state.selectedOrderRowKeys.includes(res.id)) {
    //       state.orderAmt += Number(res.settableAmt)
    //       res.applyAmt = Number(res.settableAmt)
    //     }
    //   })
    // }
    const editReconciliation = () => {
      // state.reconciliation.orderSettlementIds.forEach((item, index) => {
      //   state.reconciliation.orderSettlementIds[index].applyAmt = state.orderList[index].settableAmt
      // })
      // console.log(state)
      reconciliationEdit({
        ...state.reconciliation
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          emit('ok')
          orderSearchData()
        }
      })
    }

    onMounted(loadOrderPage)
    return {
      ...toRefs(state),
      orderListRef,
      transportListRef,
      paymentRef,
      loadOrderPage,
      orderSearchData,
      selectOrderChange,
      editReconciliation,
      editBtn
    }
  }
}
</script>
<style lang="less" scoped>
@import 'index.less';
:deep(.ant-card-extra){
  float: left;
  margin-left: 0;
}
</style>
