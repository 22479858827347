<template>
  <a-card>
    <template #title>
      <div class="flex ju-between">
        <span> 对账详情</span>
        <span> <a-button v-show="orderList?.length>0" type="primary" @click="openInvoicing">去开票</a-button></span>
      </div>
    </template>
    <a-row>
      <a-col :span="4">
        <a-form-item label="客户名">
          {{ reconciliationDetail.customerName }}
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="对账人">
          {{ reconciliationDetail.sponsor }}
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-form-item label="对账金额">
          {{ reconciliationDetail.amt }}
        </a-form-item>
      </a-col>
      <a-col v-if="reconciliationDetail.status === 0" :span="4" style="text-align: right;">
        <a-button size="large" @click="initiateSettlement" danger>发起结算</a-button>
      </a-col>
      <a-col v-else :span="4" style="text-align: right;">
        <span>已申请结算</span>
      </a-col>
      <a-col :span="4" v-show="reconciliationDetail.amt * 1 > 0 && reconciliationDetail.status === 0" style="text-align: right;">
        <a-button @click="openPayQRC">生成支付码</a-button>
      </a-col>
    </a-row>
  </a-card>

  <a-modal v-model:visible="QRC" title="二维码" footer="">
    <div class="QRC">
      <a-spin v-show="QRCloading" tip="Loading..." />
      <QrcodeVue id="QRCpayImg" v-show="!QRCloading && QRCcontext === ''" :value="QRCurl" :size="200" level="H" />
      <div v-show="QRCcontext !== ''" style="text-align: center;">{{ QRCcontext }}</div>
    </div>
  </a-modal>

  <a-card title="订单明细" :style="{ marginTop: '24px' }" :bodyStyle="{ padding: 0 }">
    <template #extra>
      <a-space>
        <a-button v-if="reconciliationDetail.status === 0" type="primary" @click="addOrder">添加订单</a-button>
        <a-button @click="exportExcel(1)">导出Excel</a-button>
      </a-space>
    </template>
    <a-table :dataSource="orderList" :rowKey="record => record.id" size="small" bordered :scroll="{x:1600}">
      <a-table-column v-if="reconciliationDetail.status === 0" :width="50" title="操作" data-index="transId">
        <template #default="{ record }">
          <a-popconfirm title="确定删除?" @confirm="deleteById(record.id, 1)">
            <a>删除</a>
          </a-popconfirm>
        </template>
      </a-table-column>
      <a-table-column :width="100" title="合同编号" data-index="orderId" />

      <a-table-column :width="80" title="结算类型" data-index="settlementMode.label" />
      <a-table-column :width="80" title="挂账类型" data-index="creditType.label" />
      <a-table-column :width="150" title="客户名" data-index="customerName" />
      <a-table-column :width="80" title="业务员" data-index="salesman" />
      <a-table-column :width="100" title="起运地" data-index="startAddress" />
      <a-table-column :width="100" title="目的地" data-index="endAddress" />
      <a-table-column :width="150" title="下单时间" data-index="orderTime" />
      <a-table-column :width="150" title="起运时间" data-index="startTime" />
      <a-table-column :width="80" title="合同金额" data-index="contractAmt" />
      <a-table-column :width="80" title="申请结算" data-index="nowSettledAmt" />
      <a-table-column :width="80" title="已结算" data-index="settledAmt" />
      <!-- <template #default="{record}">
          <div>
            <span v-show="editStatus"> {{record.paymentArrival}}</span>
            <a-input v-show="!editStatus" v-model:value="record.paymentArrival" /><br />
            <a @click="editBtn(record)">{{editStatus ? '编辑' : '确认'}}</a>
            <a @click="editStatus = !editStatus" v-show="!editStatus">取消</a>
          </div>
        </template>
      </a-table-column> -->
      <a-table-column :width="80" title="是否打开开票" data-index="isInvoice.label" />
      <a-table-column :width="220" title="小车信息" data-index="vehicleList">
        <template #default="{ record }">
          <div v-for="(item, index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{ item.vinNo }} 金额：{{ item.transportFee }}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column :width="100" title="到收金额" data-index="paymentArrival" />
      <a-table-column :width="80" title="结算方式" data-index="orderSettlement.label" />
      <a-table-column :width="80" title="结算状态" data-index="settlementStatus.label" />
    </a-table>
  </a-card>
  <a-card title="运单明细" :style="{ marginTop: '24px' }" v-if="reconciliationDetail.type === 2" :bodyStyle="{ padding: 0 }">
    <template #extra>
      <a-space>
        <a-button v-if="reconciliationDetail.status === 0" type="primary" @click="addTrans">添加运单</a-button>
        <a-button @click="exportExcel(2)">导出Excel</a-button>
      </a-space>
    </template>
    <a-table :dataSource="transportList" :rowKey="record => record.id" size="small" bordered>
      <a-table-column v-if="reconciliationDetail.status === 0" :width="50" title="操作" data-index="transId">
        <template #default="{ record }">
          <a-popconfirm title="确定删除?" @confirm="deleteById(record.id, 2)">
            <a>删除</a>
          </a-popconfirm>
        </template>
      </a-table-column>
      <a-table-column :width="200" title="运单编号" data-index="transId" />
      <a-table-column width="5%" title="结算类型" data-index="settlementMode.label" />
      <a-table-column :width="100" title="车牌号/司机" data-index="transName" />
      <a-table-column :width="100" title="承运商" data-index="carrierName" />
      <a-table-column :width="100" title="线路" data-index="lineName" />
      <a-table-column :width="100" title="发车时间" data-index="startTime" />
      <a-table-column :width="100" title="类型" data-index="truckType.label" />
      <a-table-column :width="100" title="派车人" data-index="loader" />
      <a-table-column :width="100" title="到达时间" data-index="endTime" />
      <a-table-column :width="100" title="总结算金额" data-index="settableAmt" />
      <a-table-column :width="120" title="小车信息" data-index="vehicleList">
        <template #default="{ record }">
          <div v-for="(item, index) in record.vehicleList" :key="index">
            <a-tag color="#108ee9" style="cursor: pointer;">车架号：{{ item.vinNo }} 金额：{{ item.transportFee }}元</a-tag><br />
          </div>
        </template>
      </a-table-column>
      <a-table-column :width="100" title="油卡结算金额" data-index="settableOilAmt" />
      <a-table-column :width="100" title="转账结算金额" data-index="settableCardAmt" />
    </a-table>
  </a-card>
  <a-modal v-model:visible="visible" :title="customer" :width="600" destroyOnClose>
    <component ref="advancedPaymentRef" :is="flowForm" :flowType="flowType" :customerName="reconciliationDetail.customerName" :customerType="reconciliationDetail.type" :amount="reconciliationDetail.amt" :oilAmt="oilAmt" :cardAmt="cardAmt" :customerId="reconciliationDetail.customerId" :settlementInfo="settlementInfo" @ok="applySuccess" />
  </a-modal>
  <a-modal v-model:visible="addVisible" title="订单明细" width="90%" footer="" destroyOnClose>
    <ReconciliationOrder :reconciliationId="reconciliationId" :customerId="customerId" :type="reconciliationType" @ok="addOrderSuccess" />
  </a-modal>
  <a-modal v-model:visible="addTransVisible" title="运单明细" width="90%" footer="" destroyOnClose>
    <ReconciliationTrans :reconciliationId="reconciliationId" :customerId="customerId" :type="reconciliationType" @ok="addOrderSuccess" />
  </a-modal>
  <PayQRC ref="payQRCref" />
  <a-modal v-model:visible="okVisible" title="开票" width="80%" destroyOnClose footer="">
    <InvoicingModal @submitOk="submitOk" :dataTotal="dataVehTotal" :reconciliationDetail="reconciliationDetail" :invoicingList="invoicingList"></InvoicingModal>
  </a-modal>
  <!-- 开票明细 -->
  <LookInitiate :invoiceCarrierList="invoiceCarrier.opt" v-if="lookVisible" :lookID="lookID" v-model:visible="lookVisible"></LookInitiate>
</template>
<script>
import { reconciliationExport, reconciliationDetail, reconciliationDeleteByDetailId, getsandqrcode } from '@/api/trans/finance/bill'
import { reactive, ref, toRefs, onMounted } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { message } from 'ant-design-vue'
import { getSubjectList, reconciliationVehInvoice } from '@/api/trans/order'
import Receive from '@/views/finance/settlement/components/Receive'
import Payment from '@/views/finance/settlement/components/Payment'
import AdvancedPayment from '@/views/finance/settlement/components/AdvancedPayment'
import ReconciliationOrder from '@/views/finance/income/ReconciliationOrder'
import ReconciliationTrans from '@/views/finance/income/ReconciliationTrans'
import PayQRC from '@/views/components/payQRC'
import LookInitiate from '../InitiateBilling/lookInitiate/index.vue' //开票明细
import InvoicingModal from './invoicingModal.vue'
import { compute, duplicateRemoval } from '@/utils/util'
export default {
  props: {
    reconciliationId: { type: String, default: '' }
  },
  setup (props) {
    const advancedPaymentRef = ref(null)
    const payQRCref = ref(null)
    const orderListRef = ref()
    const transportListRef = ref()
    const paymentRef = ref()
    const invoicingList = ref([])
    const state = reactive({
      dataVehTotal: 0,
      okVisible: false,
      invoiceCarrier: {
        value: null,
        width: 180,
        placeholder: '开票主体',
        opt: []
      },
      lookVisible: false,
      lookID: '',
      editStatus: true,
      orgs: [],
      reconciliationId: '',
      QRCcontext: '',
      QRCurl: '',
      flowForm: Receive,
      QRC: false,
      QRCloading: false,
      addTransVisible: false,
      addForm: ReconciliationOrder,
      flowType: 2,
      customerId: '',
      reconciliationType: '0',
      selectedOrderRowKeys: [],
      selectedTransRowKeys: [],
      carriers: [],
      visible: false,
      customer: '客户结算',
      addVisible: false,
      addTitle: '订单添加',
      total: {},
      orderList: [],
      orderPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.orderPagination.current = page
          state.orderPagination.pageSize = pageSize
        }
      },
      transportList: [],
      transportPagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.transportPagination.current = page
          state.transportPagination.pageSize = pageSize
        }
      },
      timeOut: null,
      orderAmt: 0,
      transAmt: 0,
      reconciliationDetail: {
        customerId: '',
        customerName: '',
        type: 1,
        amt: 0,
        orderSettlementDetailDTOS: [],
        transSettlementDetailDTOS: []
      },
      settlementInfo: {
        billApplyId: props.reconciliationId,
        orderSettlementIds: [],
        transSettlementIds: []
      },
      oilAmt: 0,
      cardAmt: 0
    })
    const submitOk = (id) => {
      state.lookID = id
      state.lookVisible = true
      state.okVisible = false
    }
    const openInvoicing = () => {
      reconciliationVehInvoice({
        customerName: state.reconciliationDetail.customerName,
        customerId: state.reconciliationDetail.customerId,
        frid: state.reconciliationDetail.id
      }).then(res => {
        console.log(res);
        invoicingList.value = res.data
        state.okVisible = true
      })

    }
    // 支付码
    const QRCpayImg = () => {
      state.status = 1
      state.QRC = true
      state.QRCloading = true
      // console.log(state.id)
      if (state.QRCpay === '') state.QRCloading = true
      getsandqrcode({
        frId: state.reconciliationId,
        payType: '1'
      }).then(res => {
        if (res.code === 10000) {
          state.QRCurl = res.data.qrCode
          state.QRCcontext = ''
        }
        if (res.code === 10001) {
          state.QRCcontext = res.msg
        }
      }).finally(() => { state.QRCloading = false })
    }
    const onSearch = () => {
      state.orderPagination.current = 0
      state.transportPagination.current = 0
    }

    const exportExcel = (type) => {
      let title = '财务-订单对账明细'
      if (type === 2) {
        title = '财务-运单对账明细'
      }
      reconciliationExport(state.reconciliationId, type, title).then(res => {
      })
    }

    const selectOrderChange = (selectedRowKeys, selectedRows) => {
      state.selectedOrderRowKeys = selectedRowKeys
      state.reconciliationDetail.orderSettlementIds = selectedRowKeys.map(item => { return { id: item } })
      clearAccounts()
    }
    const selectTransChange = (selectedRowKeys, selectedRows) => {
      state.selectedTransRowKeys = selectedRowKeys
      state.reconciliationDetail.orderSettlementIds = selectedRowKeys.map(item => { return { id: item } })
      clearAccounts()
    }
    const clearAccounts = () => {
      state.orderAmt = 0
      state.orderList.forEach(res => {
        if (state.selectedOrderRowKeys.includes(res.id)) {
          state.orderAmt += Number(res.settableAmt)
        }
      })
      state.transAmt = 0
      state.transportList.forEach(item => {
        if (state.selectedTransRowKeys.includes(item.id)) {
          state.transAmt += Number(item.settableAmt)
        }
      })
    }
    const getReconciliation = () => {
      state.reconciliationId = props.reconciliationId
      state.settlementInfo.orderSettlementIds = []
      state.settlementInfo.transSettlementIds = []
      reconciliationDetail(state.reconciliationId).then(res => {
        if (res.code === 10000) {
          state.orderAmt = 0
          state.reconciliationDetail = res.data
          state.orderList = state.reconciliationDetail.orderSettlementDetailDTOS
          state.transportList = state.reconciliationDetail.transSettlementDetailDTOS
          if (state.orderList !== null && state.orderList.length > 0) {
            state.orderList.forEach(item => {
              state.settlementInfo.orderSettlementIds.push({ id: item.id, applyAmt: item.nowSettledAmt })
              state.orderAmt += Number(item.nowSettledAmt)
            })
            let  vehList = duplicateRemoval(state.orderList,'orderId')
            state.dataVehTotal = compute('+', ...vehList.map(item => item.vehicleList.length)).result
          }
          let oilAmt = 0
          let cardAmt = 0
          if (state.transportList !== null && state.transportList.length > 0) {
            state.transportList.forEach(item => {
              state.settlementInfo.transSettlementIds.push({ id: item.id, applyAmt: item.settableAmt })
              // 油卡
              oilAmt += Number(item.settableOilAmt)
              // 转账
              cardAmt += Number(item.settableCardAmt)
              state.transAmt += Number(item.settableAmt)
            })
          }
          if (state.orderAmt < cardAmt) {
            state.cardAmt = cardAmt - state.orderAmt
            state.oilAmt = oilAmt
          } else {
            const over = state.orderAmt - cardAmt
            state.cardAmt = 0
            if (over < oilAmt) {
              state.oilAmt = oilAmt - over
            }
          }
        } else {
          message.error(res.msg)
        }
      })
    }
    const initiateSettlement = () => {
      if (state.reconciliationDetail.type === 2) {
        if (Number(state.reconciliationDetail.amt) > 0) {
          state.flowForm = Receive
          state.flowType = 0
        } else {
          state.flowForm = AdvancedPayment
          state.flowType = 1
          setTimeout(() => {
            advancedPaymentRef.value.carrierClient()
          }, 50)
        }
      } else {
        if (Number(state.reconciliationDetail.amt) > 0) {
          state.flowType = 2
          state.flowForm = Receive
        } else {
          // 当需要付款给客户时，认定为返款流程
          state.flowForm = AdvancedPayment
          state.flowType = 3
          setTimeout(() => {
            advancedPaymentRef.value.carrierClient()
          }, 50)
        }
      }
      state.visible = true
    }
    const applySuccess = () => {
      state.visible = false
      getReconciliation()
    }
    const addOrder = () => {
      state.reconciliationType = state.reconciliationDetail.type
      state.customerId = state.reconciliationDetail.customerId
      state.reconciliationId = props.reconciliationId
      state.addVisible = true
    }
    const addTrans = () => {
      state.reconciliationType = state.reconciliationDetail.type
      state.customerId = state.reconciliationDetail.customerId
      state.reconciliationId = props.reconciliationId
      state.addTransVisible = true
    }
    const addOrderSuccess = () => {
      getReconciliation()
    }
    const deleteById = (detailId, type) => {
      reconciliationDeleteByDetailId(state.reconciliationId, detailId, type).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getReconciliation()
        }
      })
    }
    // const editBtn = (record) => {
    //   if (Number(record.contractAmt) >= 0) {
    //     if (state.reconciliationDetail.status !== 0) {
    //       message.error('结算已申请,请等待结果')
    //       return
    //     }
    //     if (Number(record.paymentArrival) <= state.reconciliationDetail.amt) {
    //       state.reconciliationDetail.amt = Number(record.paymentArrival)
    //     } else {
    //       message.error('数额高于最大值')
    //       return
    //     }
    //   } else {
    //     message.error('数额不能小于0')
    //     return
    //   }
    //   state.editStatus = !state.editStatus
    // }
    onMounted(() => {
      getReconciliation()
      reqGetSubjectList(0)
    })
    // 获取主体列表
    const reqGetSubjectList = (id, name) => {
      getSubjectList(id).then(res => {
        if (res.code !== 10000) return
        if (id == 0) {
          state.invoiceCarrier.opt = res.data
        } else {
          res.data.forEach(item => {
            item.label = item.subjectName + `【${item.taxItem}】`
          })
          state.taxItem.opt = res.data
        }
      })
    }
    const openPayQRC = () => {
      let obj = {
        startAddress: null,
        endAddress: null,
        frId: state.reconciliationId,
        orderId: state.reconciliationId,
        vehicleList: []
      }
      setTimeout(() => {
        payQRCref.value.openWindow(obj)
      }, 10)
    }
    return {
      ...toRefs(state),
      payQRCref,
      orderListRef,
      transportListRef,
      advancedPaymentRef,
      paymentRef,
      QRCpayImg,
      openPayQRC,
      onSearch,
      exportExcel,
      selectOrderChange,
      selectTransChange,
      getReconciliation,
      initiateSettlement,
      applySuccess,
      addOrder,
      addOrderSuccess,
      deleteById,
      addTrans,
      openInvoicing,
      submitOk,
      invoicingList
    }
  },
  components: {
    PayQRC,
    QrcodeVue,
    Receive,
    AdvancedPayment,
    Payment,
    ReconciliationOrder,
    ReconciliationTrans,
    LookInitiate,
    InvoicingModal
  }
}
</script>
<style lang="less" scoped>
@import 'index.less';

.QRC {
  display: flex;
  justify-content: center;
}
</style>
